import React, { useEffect, useRef } from "react";
import type { ResponsiveImage } from "../types";
import { RImage } from "./RImage";
export type ImageScrollerProps = {
	images: ResponsiveImage[]; // Array of image URLs
	speed?: number; // Speed of scrolling in pixels per frame
};

const ImageScroller: React.FC<ImageScrollerProps> = ({
	images,
	speed = 1.5,
}) => {
	const scrollerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const interval = setInterval(() => {
			if (scrollerRef.current) {
				// Calculate the new scroll position
				let newScrollLeft = scrollerRef.current.scrollLeft + speed;

				// Reset scroll position if end is reached
				if (newScrollLeft >= scrollerRef.current.scrollWidth / 2) {
					newScrollLeft = 0;
				}

				scrollerRef.current.scrollLeft = newScrollLeft;
			}
		}, 16); // approximately 60 frames per second

		return () => clearInterval(interval);
	}, [speed]);

	return (
		<div
			ref={scrollerRef}
			className="h-full w-full"
			style={{
				overflow: "hidden",
				whiteSpace: "nowrap",
				display: "flex",
				width: "100%",
			}}
		>
			{/* Duplicate images array for wrap-around effect */}
			{[...images, ...images].map((image, index) => (
				<div
					key={`image-${index}`}
					className="shrink-0 overflow-hidden scale-100 hover:scale-105  p-4 brightness-100 sm:brightness-85 hover:brightness-100 transition-all duration-500"
					style={{ width: 325, height: 325 }}
				>
					<RImage
						desktop={image.desktop}
						mobile={image.mobile}
						className="w-full h-full object-cover rounded-lg"
						alt={`image-${index}`}
					/>
				</div>
			))}
		</div>
	);
};

export default ImageScroller;
