import React from "react";
import ImageScroller, { type ImageScrollerProps } from "./ImageScroller";
import type { Section } from "../types";
import { Content } from "./Content";

export type WhoWeAreProps = {
	section: Section;
	images: ImageScrollerProps["images"];
};

export const WhoWeAre: React.FC<WhoWeAreProps> = ({ section, images }) => {
	return (
		<div className="relative bg-white">
			<div className="bg-gradient-to-b from-white via-teal-200/15 to-white relative isolate overflow-hidden   pb-16  pt-20  sm:pt-28 gap-10 flex flex-col border-b border-stone-300">
				<div className="px-6  lg:px-8">
					<div className="mx-auto max-w-2xl fadeInUp text-center ">
						<h2 className="font-serif  font-bold tracking-tight text-stone-800 text-4xl 2xs:text-4xl xs:text-5xl sm:text-5xl lg:text-7xl">
							{section.title}
						</h2>
						<p className="mt-6 text-lg leading-8 text-stone-800">
							<Content text={section.content} />
						</p>
						<div className="mt-12">
							<a href="/who-we-are">
								<button
									type="button"
									className="hover:-translate-y-1 translate-y-0 fadeInUp transition-all rounded-md bg-teal-500  sm:w-auto px-6 py-2 sm:px-5 sm:py-3  tracking-wide text-lg md:text-xl font-medium text-white shadow-sm hover:bg-teal-600 border border-teal-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 "
								>
									Learn more
								</button>
							</a>
						</div>
					</div>
				</div>
				<ImageScroller images={images} />
			</div>
		</div>
	);
};
